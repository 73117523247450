
import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../../../../network/api";
import { baseUrl } from "../../../../network/constants/constants";
import config from "../../../../network/auth";
import AppConstants from '../../../AppConstants';
import * as XLSX from 'xlsx';
import "./subscription.css"
import DownloadIcon from '@mui/icons-material/Download';
import { Subscriptions } from '@mui/icons-material';

const Subscription = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  let bID = localStorage.getItem(AppConstants.business_id);

  // fetch subscription
  const fetchSubscription = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/subscriptionByBusinessIDList/${bID}`, config)
      .then((response) => {
        setSubscriptions(response.data.subscriptions);
        setFilteredSubscriptions(response.data.subscriptions);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Subscription Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSubscription();
  }, []);

  const handleFilterSubscriptions = () => {
    const filtered = subscriptions.filter(subscription => {
      const orderDate = new Date(subscription.order_paid_date);
      const start = new Date(startDate);
      const end = new Date(endDate);

      return orderDate >= start && orderDate <= end;
    });
    setFilteredSubscriptions(filtered);
    console.log(filteredSubscriptions)
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "order_paid_date", headerName: "Order Paid Date", width: 200 },
    { field: "subscription_amount", headerName: "Subscription Amount", width: 200 },
    { field: "order_payment_id", headerName: "Order Payment ID", width: 150 },
    { field: "status", headerName: "Status", width: 200 },
    { field: "visits_left", headerName: "Visits Left", width: 100 },
    { field: "subscription_product_name", headerName: "Subscription Product Name", width: 200 },
    { field: "customer_name", headerName: "Customer Name", width: 150 },
  ];

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredSubscriptions);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Subscriptions");
    XLSX.writeFile(workbook, "Subscriptions.xlsx");
  };
  const handleReset = () => {
    setFilteredSubscriptions(subscriptions);
    setStartDate("");
    setEndDate("");
  };

  return (
    <div>
      <Box className="box-container">
        <div className="download-excel">
          
          <div className="date-input-container">
          <input 
          className='date-input'
            type="date" 
            value={startDate} 
            onChange={(e) => setStartDate(e.target.value)} 
            placeholder="Start Date" 
          />
          <input 
             className='date-input'
            type="date" 
            value={endDate} 
            onChange={(e) => setEndDate(e.target.value)} 
            placeholder="End Date" 
          />
          <button className='filter-btn' onClick={handleFilterSubscriptions} >
            Apply Rang
            </button>
            <button className="filter-btn" onClick={handleReset}>
              Reset
            </button>
          </div>
        
          <Button variant="contained" color="primary" onClick={exportToExcel} style={{ marginBottom: '10px' }}>
            <DownloadIcon /> Download
          </Button>
        </div>
        <DataGrid
          pageSize={20}
          autoHeight
          getRowId={(row) => row.id}
          rows={filteredSubscriptions}
          columns={columns}
          editMode="row"
          loading={loading}
        />
      </Box>
    </div>
  );
};

export default Subscription;


