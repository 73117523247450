import React, { useState, useEffect } from 'react';
import "./dashboard.css";
import Card from 'react-bootstrap/Card';
import axios from "../../../../network/api";
import { baseUrl } from "../../../../network/constants/constants";
import config from "../../../../network/auth";
import AppConstants from '../../../AppConstants';
import Spinner from '../loader/loader';

const Dashboard = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [totalSubscriptions, setTotalSubscriptions] = useState(0);
  const [cancelledSubscriptions, setCancelledSubscriptions] = useState(0);
  const [inactiveSubscriptions, setInactiveSubscriptions] = useState(0);
  const [loading, setLoading] = useState(false);

  let bID = localStorage.getItem(AppConstants.business_id);

  // fetch subscription
  const fetchSubscriptions = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/subscriptionByBusinessIDList/${bID}`, config)
      .then((response) => {
        const data = response.data;
        setSubscriptions(data.subscriptions);
        setTotalSubscriptions(data.total_subscriptions);
        setCancelledSubscriptions(data.cancelled_subscriptions);
        setInactiveSubscriptions(data.inactive_subscriptions);
        setLoading(false);
      })
      .catch((error) => {
        console.error("subscription Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  return (
    <div className="plan-container">
       {loading ? (
        <Spinner />
      ) : (
        <>
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>{totalSubscriptions}</Card.Title>
          <Card.Text>Total Subscription</Card.Text>
        </Card.Body>
      </Card>
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>{cancelledSubscriptions}/{totalSubscriptions}</Card.Title>
          <Card.Text>Cancelled Subscription</Card.Text>
        </Card.Body>
      </Card>
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>{inactiveSubscriptions}/{totalSubscriptions}</Card.Title>
          <Card.Text>Inactive Subscription</Card.Text>
        </Card.Body>
      </Card>
      </>
      )}
    </div>
  );
}

export default Dashboard;
