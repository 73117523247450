import "./settings.css";
import React, { useState, useEffect } from "react";
import "reactjs-popup/dist/index.css";
import AppConstants from "../../../AppConstants";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import axios from "../../../../network/api";
import config from "../../../../network/auth";
import { baseUrl } from "../../../../network/constants/constants";
import { MdOutlineFileUpload, MdClose } from "react-icons/md";
import TextField from "@mui/material/TextField";
import Popup from "reactjs-popup";
import Spinner from "../loader/loader";
import FormData from "form-data";
import { RiImageEditLine } from "react-icons/ri";
import { Snackbar, Alert } from "@mui/material";

function Settings() {
  const [editPopupOpen, setEditPopupOpen] = useState(false);
  const [business, setBusiness] = useState([]);
  const [editedBusiness, setEditedBusiness] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [selectedThumb, setSelectedThumb] = useState(null);
  const [bannerPopupOpen, setBannerPopupOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [errors, setErrors] = useState({});

  let bID = localStorage.getItem(AppConstants.business_id);

  // phone validation
  const validatePhoneNumber = (number) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(number);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let tempErrors = {};

    if (!validatePhoneNumber(editedBusiness.mobile_number)) {
      tempErrors.mobile_number = "Invalid phone number.";
    }

    if (Object.keys(tempErrors).length === 0) {
      setErrors({});
      handleEditBusiness();
    } else {
      setErrors(tempErrors);
    }
  };

  // fetch business details
  const fetchBusinessDetails = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/business-details/${bID}/`, config)
      .then((response) => {
        const businessData = response.data;
        console.log(response.data)
        setBusiness(businessData ? [businessData] : []);
        setEditedBusiness({
          business_title: businessData.business_title,
          mobile_number: businessData.mobile_number,
          category_name: businessData.category_name,
          description: businessData.description,
          service_range_km: businessData.service_range_km,
          service_localities: businessData.service_localities,
          place: businessData.place,
          latitude: businessData.latitude,
          longitude: businessData.longitude,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Business Details Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBusinessDetails();
  }, []);

  const handleChangeBusiness = (event) => {
    const { name, value } = event.target;
    setEditedBusiness((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // edit business details
  const handleEditBusiness = (event) => {
    // event.preventDefault();
    setLoading(true);
    setSnackbarOpen(true);

    const payload = { ...editedBusiness };

    axios
      .patch(`${baseUrl}app/business/edit/${bID}/`, payload, config)
      .then((response) => {
        fetchBusinessDetails();
        setLoading(false);
        setEditPopupOpen(false);
        setSnackbarMessage(response.data.message);
      })
      .catch((error) => {
        console.error("Edit Business Error:", error);
        setLoading(false);
        setSnackbarMessage(error.response.data.message);
      });
  };

  

  // patch banner
  const handleBannerSubmit = (event) => {
    // event.preventDefault();
    setLoading(true);
    setSnackbarOpen(true);
    const formData = new FormData();
    formData.append("banner", selectedFile);
    // formData.append("link_type", "ext_link");

    axios
      .patch(`${baseUrl}app/business/edit/${bID}/`, formData, config)
      .then((response) => {
        fetchBusinessDetails();
        // console.log("Submitted: ", response.data);
        setSelectedFile(response.data);
        setLoading(false);
        setBannerPopupOpen(false);
        setSnackbarMessage(response.data.message);
      })
      .catch((error) => {
        console.error("Error: ", error);
        setLoading(false);
        setSnackbarMessage(error.response.data.message);
      });
  };

  // patch logo
  const handleLogoSubmit = (event) => {
    setLoading(true);
    setSnackbarOpen(true);

    const formData = new FormData();
    formData.append("logo", selectedLogo);
    

    axios
      .patch(`${baseUrl}app/business/edit/${bID}/`, formData, config)
      .then((response) => {
        fetchBusinessDetails();
        // console.log("Submitted:", response.data);
        setSelectedLogo(response.data);
        setLoading(false);
        setBannerPopupOpen(false);
        setSnackbarMessage(response.data.message);
      })
      .catch((error) => {
        console.error("Error: ", error);
        setLoading(false);
        setSnackbarMessage(error.response.data.message);
      });
  };

  // patch thumb
  const handleThumbSubmit = (event) => {
    setLoading(true);
    setSnackbarOpen(true);

    const formData = new FormData();
    formData.append("thumb", selectedThumb);
    // formData.append("link_type", "ext_link");

    axios
      .patch(`${baseUrl}app/business/edit/${bID}/`, formData, config)
      .then((response) => {
        fetchBusinessDetails();
        // console.log("Submitted: ", response.data);
        setSelectedThumb(response.data);
        setLoading(false);
        setBannerPopupOpen(false);
        setSnackbarMessage(response.data.message);
      })
      .catch((error) => {
        console.error("Error: ", error);
        setLoading(false);
        setSnackbarMessage(error.response.data.message)
      });
  };

  return (
    <div className="main-div">
      <div className="mt-5 business-details-head">Banner</div>
      <Card className="business-card">
        {loading ? (
          <div className="loader-container">
            <Spinner />
          </div>
        ) : (
          <div className="add-banner-container">
            <Popup
              className="add-popup-container"
              contentStyle={{ borderRadius: "20px" }}
              trigger={
                <button
                  className="btn-add-banner"
                  onClick={() => setBannerPopupOpen(true)}
                >
                  + Change Banner
                </button>
              }
              modal
              nested
              open={bannerPopupOpen}
              onClose={() => setBannerPopupOpen(false)}
            >
              {(close) => (
                <div className="add-img-div">
                  <h3 className="add-img-heading">Change Banner Image</h3>
                  <form
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleBannerSubmit();
                      close();
                    }}
                  >
                    <label className="add-file-upload">
                      <input
                        className="add-img-file"
                        id="file"
                        type="file"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                      />
                      <MdOutlineFileUpload className="upload-icon" />
                      {selectedFile && <span className="file-name-label">{selectedFile.name}</span>}
                    </label>
                    <div className="submit-banner-file-container">
                      <button type="submit">Upload</button>
                      <button type="button" onClick={close}>
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </Popup>
          </div>
        )}
        {business.length === 0 ? (
          <p>No banner available to display.</p>
        ) : (
          <Row>
            {business.map((bDetails) => (
              <Col
                id={bDetails.id}
                key={bDetails.id}
                md={3}
                className="banner-item"
              >
                <div className="banner-img-div">
                  <img
                    src={`${baseUrl}${bDetails.banner}`}
                    alt="banner"
                    className="banner-images img-fluid"
                  />
                </div>
              </Col>
            ))}
          </Row>
        )}
      </Card>
      <div className="mt-5 business-details-head">Thumb</div>
      <Card className="business-card">
        {loading ? (
          <div className="loader-container">
            <Spinner />
          </div>
        ) : (
          <div className="add-banner-container">
            <Popup
              className="add-popup-container"
              contentStyle={{ borderRadius: "20px" }}
              trigger={
                <button
                  className="btn-add-banner"
                  onClick={() => setBannerPopupOpen(true)}
                >
                  + Change Thumb
                </button>
              }
              modal
              nested
              open={bannerPopupOpen}
              onClose={() => setBannerPopupOpen(false)}
            >
              {(close) => (
                <div className="add-img-div">
                  <h3 className="add-img-heading">Change Thumb Image</h3>
                  <form
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleThumbSubmit();
                      close();
                    }}
                  >
                    <label className="add-file-upload">
                      <input
                        className="add-img-file"
                        id="file"
                        type="file"
                        onChange={(e) => setSelectedThumb(e.target.files[0])}
                      />
                      <MdOutlineFileUpload className="upload-icon" />
                      {selectedThumb && <span className="file-name-label">{selectedThumb.name}</span>}
                    </label>
                    <div className="submit-banner-file-container">
                      <button type="submit">Upload</button>
                      <button type="button" onClick={close}>
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </Popup>
          </div>
        )}
        {business.length === 0 ? (
          <p>No thumb available to display.</p>
        ) : (
          <Row className="">
            {business.map((bDetails) => (
              <Col key={bDetails.id} md={3} className="banner-item">
                <div className="thumb-img-div">
                  <img
                    src={`${baseUrl}${bDetails.plans[0].sub_plans[0].thumb}`}
                    alt="banner"
                    className="banner-images "
                  />
                </div>
              </Col>
            ))}
          </Row>
        )}
      </Card>
      <div className="business-details-map">
        {business.length === 0 ? (
          <p>No business details available to display.</p>
        ) : (
          business.map((bDetails) => (
            <React.Fragment key={bDetails.id}>
              <div className="mt-5 business-details-head">Business Details</div>
              <Card className="business-card">
                <Container className="business-row pb-3 pb-lg-4">
                  <div className="business_container">
                    <div className="logo-edit-container">
                      <img
                        className="business_image"
                        src={`${baseUrl}${bDetails.logo}`}
                        alt="Business"
                      />
                      <Popup
                        className="add-popup-container"
                        contentStyle={{ borderRadius: "20px" }}
                        trigger={<RiImageEditLine className="logo-edit" />}
                        modal
                        nested
                        open={bannerPopupOpen}
                        onClose={() => setBannerPopupOpen(false)}
                      >
                        {(close) => (
                          <div className="add-img-div">
                            <h3 className="add-img-heading">
                              change logo Image
                            </h3>
                            <form
                              method="post"
                              onSubmit={(e) => {
                                e.preventDefault();
                                handleLogoSubmit();
                                close();
                              }}
                            >
                              <label className="add-file-upload">
                                <input
                                  className="add-img-file"
                                  id="file"
                                  type="file"
                                  onChange={(e) =>
                                    setSelectedLogo(e.target.files[0])
                                  }
                                />
                                <MdOutlineFileUpload className="upload-icon" />
                                {selectedLogo && <span className="file-name-label">{selectedLogo.name}</span>}
                              </label>
                              <div className="submit-banner-file-container">
                                <button type="submit">Upload</button>
                                <button type="button" onClick={close}>
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        )}
                      </Popup>
                    </div>
                    <div className="business_details">
                      <div className="business_name">
                        {bDetails.business_title}
                      </div>
                      <div className="phone_number">
                        {bDetails.mobile_number}
                      </div>
                    </div>
                  </div>
                  <Button
                    className="edit-btn d-lg-block"
                    variant="success"
                    onClick={() => setEditPopupOpen(true)}
                  >
                    Edit
                  </Button>
                </Container>

                <Container>
                  <Row>
                    <Col lg={6} className="mt-3 mt-lg-5">
                      <div className="key-label">Business Name</div>
                      <div className="key-value">{bDetails.business_title}</div>
                    </Col>
                    <Col lg={6} className="mt-3 mt-lg-5">
                      <div className="key-label">Business Phone</div>
                      <div className="key-value">{bDetails.mobile_number}</div>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <Row>
                    <Col lg={6} className="mt-3 mt-lg-5">
                      <div className="key-label">Category</div>
                      <div className="key-value">{bDetails.category_name}</div>
                    </Col>
                    <Col lg={6} className="mt-3 mt-lg-5">
                      <div className="key-label">Description</div>
                      <div className="key-value">{bDetails.description}</div>
                    </Col>
                  </Row>
                </Container>

                <Popup
                  contentStyle={{ backgroundColor: "white" }}
                  open={editPopupOpen}
                  onClose={() => setEditPopupOpen(false)}
                  modal
                  nested
                >
                  {(close) => (
                    <div>
                      <div className="close-btn" onClick={close}>
                        <MdClose />
                      </div>
                      <div className="edit-div-subplan">
                        <form onSubmit={handleSubmit}>
                          <h3 className="edit-heading">
                            Edit Business Details
                          </h3>

                          <TextField
                            className="plan-form-textfield"
                            name="business_title"
                            label="Business Name"
                            variant="outlined"
                            size="small"
                            value={editedBusiness.business_title || ""}
                            onChange={handleChangeBusiness}
                          />
                          <TextField
                            className="plan-form-textfield"
                            name="mobile_number"
                            label="Phone Number"
                            variant="outlined"
                            size="small"
                            value={editedBusiness.mobile_number || ""}
                            onChange={handleChangeBusiness}
                            error={!!errors.mobile_number}
                            helperText={errors.mobile_number}
                          />
                          <TextField
                            className="plan-form-textfield"
                            name="description"
                            label="Description"
                            variant="outlined"
                            size="small"
                            multiline
                            rows={4}
                            value={editedBusiness.description || ""}
                            onChange={handleChangeBusiness}
                          />
                           <TextField
                                className="plan-form-textfield"
                                name="place"
                                label="Place"
                                variant="outlined"
                                size="small"
                                value={editedBusiness.place || ""}
                                onChange={handleChangeBusiness}
                              />
                          <div className="form-location">
                            <div className="sub-text-container">
                              <TextField
                                className="plan-form-textfield-text"
                                name="service_range_km"
                                label="Service Range"
                                variant="outlined"
                                size="small"
                                value={editedBusiness.service_range_km || ""}
                                onChange={handleChangeBusiness}
                              />
                               <TextField
                                className="plan-form-textfield-text"
                                name="service_localities"
                                label="place,subplace"
                                variant="outlined"
                                size="small"
                                value={editedBusiness.service_localities || ""}
                                onChange={handleChangeBusiness}
                              />
                               </div>
                             
                           
                            <div className="sub-text-container">
                              <TextField
                                className="plan-form-textfield-text"
                                name="latitude"
                                label="Latitude"
                                variant="outlined"
                                size="small"
                                value={editedBusiness.latitude || ""}
                                onChange={handleChangeBusiness}
                              />
                              <TextField
                                className="plan-form-textfield-text"
                                name="longitude"
                                label="Longitude"
                                variant="outlined"
                                size="small"
                                value={editedBusiness.longitude || ""}
                                onChange={handleChangeBusiness}
                              />
                            </div>
                          </div>
                          <div className="delete-btn-div">
                            <Button type="submit">Save</Button>
                            <Button className="cancel-business-btn" onClick={close}>
                              Cancel
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </Popup>

                <Container>
                  <Row>
                    <Col lg={6} className="mt-3 mt-lg-5">
                      <div className="key-label">Service Range</div>
                      <div className="key-value">
                        {bDetails.service_range_km}
                      </div>
                    </Col>
                    <Col lg={6} className="mt-3 mt-lg-5">
                      <div className="key-label">Place</div>
                      <div className="key-value">{bDetails.place}</div>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <Row>
                    <Col lg={6} className="mt-3 mt-lg-5">
                      <div className="key-label">Latitude</div>
                      <div className="key-value">{bDetails.latitude}</div>
                    </Col>
                    <Col lg={6} className="mt-3 mt-lg-5">
                      <div className="key-label">Longitude</div>
                      <div className="key-value">{bDetails.longitude}</div>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <Row>
                    <Col lg={6} className="mt-3 mt-lg-5">
                      <div className="key-label">Service Localities</div>
                      <div className="key-value">{bDetails.service_localities}</div>
                    </Col>
                  </Row>
                </Container>
              </Card>
            </React.Fragment>
          ))
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarMessage.includes('error') ? 'error' : 'success'}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Settings;
